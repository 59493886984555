<template>
    <section id="immigrun" class="trunk">
        <div id="op-wrap">
            <router-link to="/" custom v-slot="{ navigate }">
                <button @click="navigate">
                    <span v-if="!cnDisp">Home</span>
                    <span v-if="cnDisp" class="lang-cn">首页</span>
                </button>
            </router-link>
            <button @click="toggleForm">
                <span v-if="!toPost && !cnDisp">Service Post</span>
                <span v-if="!toPost && cnDisp" class="lang-cn">服务发布</span>
                <span v-if="toPost"><i class="fas fa-arrow-left"></i> Back</span>
            </button>
            <select v-model="curLang" @change="cnDisp=['cn', 'tcn', 'jp'].includes(this.curLang)">
                <option value="en">English</option>
                <option value="fr">Français</option>
                <option value="cn" class="lang-cn">简体中文</option>
                <option value="tcn" class="lang-cn">繁體</option>
                <option value="jp">日本語</option>
                <option value="kor">한국어</option>
                <option value="vnm">tiếng Việt</option>
                <option value="es">Español</option>
                <option value="pt">Português</option>
                <option value="far" class="lang-far">فارسی</option>
            </select>
        </div>

        <div v-if="curLang === 'en'">
            <h2>AI Immigration Consultant</h2>
            <p>
                Our AI-powered immigration consultant possesses a thorough and comprehensive understanding of laws, regulations, and policies governing immigration, refugee status, study abroad, and international travel in Canada, Australia, New Zealand, and the United States. He has been amassing extensive service resources from legal to ticketing. He will be happy to answer any of your questions regarding these matters.
            </p>
            <p v-if="!toPost">
                Simply describe your specific situation or case in detail, and our AI-powered immigration consultant will help you make informed decisions and develop a preliminary plan for your starting point. To protect your privacy and data security, please refrain from providing any personal identification data.
            </p>
        </div>
        <div v-if="curLang === 'fr'">
            <h2>Consultant en Immigration IA</h2>
            <p>
                Notre consultant en immigration propulsé par l'IA possède une compréhension approfondie et complète des lois, règlements et politiques régissant l'immigration, le statut de réfugié, les études à l'étranger et les voyages transfrontaliers au Canada, en Australie, en Nouvelle-Zélande et aux États-Unis. Il a accumulé une vaste ressource de services pertinents, du juridique à la billetterie. Il sera ici heureux de répondre à toutes vos questions dans cette mesure.
            </p>
            <p v-if="!toPost">
                Décrivez simplement en détail votre situation spécifique/cas à notre consultant en immigration propulsé par l'IA, et il vous aidera à prendre des décisions éclairées et à élaborer un plan provisoire pour votre point de départ. Pour protéger votre vie privée et la sécurité de vos données, veuillez vous abstenir de fournir toute donnée d'identification personnelle.
            </p>
        </div>
        <div v-if="curLang === 'cn'" class="lang-cn">
            <h2>“润博士” AI 移民留学顾问</h2>
            <p>
                我们的人工智能移民顾问对加拿大、澳大利亚、新西兰和美国的移民和难民法、公民法、以及相关规定和政策有着深入且全面的掌握。他也了解各种相关的服务资源。在此, 他将尽心解答您有关移民、留学、难民身份、以及跨国旅行的任何问题。此外，他还熟知中国的《民法典》和《公司法》等法律法规，可以帮您对一些具体情况，比如涉及国籍等的各种问题，做法律层面的细致解读。
            </p>
            <p v-if="!toPost">
                请具体描述您的情况或案例, 我们的人工智能移民顾问将引导您在起跑线上规划一个合身的预案，并进一步辅助您做出依据充分的决策。为了保护您的隐私和数据安全，请避免提供任何个人真实身份信息。
            </p>
        </div>
        <div v-if="curLang === 'tcn'" class="lang-cn">
            <h2>AI 移民留學顧問</h2>
            <p>
                我們的人工智慧移民顧問對於加拿大、澳大利亞、紐西蘭和美國的移民與難民法、公民法，以及相關的規定與政策都有深入且全面的掌握。他也了解各種相關的服務資源。在此，他將盡心解答您有關移民、留學、難民身份以及跨國旅行的任何問題。
            </p>
            <p v-if="!toPost">
                請具體描述您的情況或案例，我們的人工智慧移民顧問將引導您在起跑線上規劃一個合適的預案，並進一步輔助您做出根據充分的決策。為了保護您的隱私和資料安全，請避免提供任何個人真實身份信息。
            </p>
        </div>
        <div v-if="curLang === 'jp'">
            <h2>AI 移民留学コンサルタント</h2>
            <p>
                私たちのAI移民コンサルタントは、カナダ、オーストラリア、ニュージーランド、アメリカの移民および難民法、市民法、および関連規定と政策に関して、深く包括的な知識を持っています。また、さまざまな関連サービスリソースについても精通しています。ここで、彼は移民、留学、難民資格、および国際旅行に関するあらゆる質問にお答えします。
            </p>
            <p v-if="!toPost">
                あなたの状況や事例を具体的に説明してください。私たちのAI移民コンサルタントは、スタートラインで適切な計画を立てるようご案内し、さらに十分な根拠に基づいた決定を下すのを支援します。プライバシーとデータの安全性を保護するために、個人の本名や識別情報を提供しないようご注意ください。
            </p>
        </div>
        <div v-if="curLang === 'kor'">
            <h2>AI 이민 유학 상담사</h2>
            <p>
                저희의 AI 이민 상담사는 캐나다, 호주, 뉴질랜드, 그리고 미국의 이민 및 난민법, 시민법, 그리고 관련 규정과 정책에 대해 깊이 있고 포괄적인 이해를 가지고 있습니다. 또한 다양한 관련 서비스 자원에 대해서도 잘 알고 있습니다. 이곳에서 그는 이민, 유학, 난민 자격, 그리고 국제 여행과 관련된 모든 질문에 성실히 답변해 드립니다.
            </p>
            <p v-if="!toPost">
                귀하의 상황이나 사례를 구체적으로 설명해 주십시오. 저희의 AI 이민 상담사는 시작 단계에서 적합한 계획을 세우도록 안내해 드리며, 충분한 근거에 따른 결정을 내릴 수 있도록 도와 드립니다. 귀하의 개인정보와 데이터 보안을 보호하기 위해 실제 신원 정보를 제공하지 않도록 유의해 주십시오.
            </p>
        </div>
        <div v-if="curLang === 'es'">
            <h2>Consultor de Inmigración IA</h2>
            <p>
                Nuestro consultor de inmigración impulsado por IA posee un entendimiento profundo y completo de las leyes, regulaciones y políticas que gobiernan la inmigración, el estatus de refugiado, estudiar en el extranjero y viajar a través de fronteras en Canadá, Australia, Nueva Zelanda y los Estados Unidos. Ha estado acumulando extensos recursos de servicios relevantes, desde legales hasta de boletaje. Estará aquí feliz de responder a cualquier pregunta que tenga hasta este punto.
            </p>
            <p v-if="!toPost">
                Simplemente describa en detalle su situación específica/caso a nuestro consultor de inmigración impulsado por IA, y él le ayudará a tomar decisiones informadas y a desarrollar un plan tentativo para su punto de partida. Para proteger su privacidad y la seguridad de sus datos, por favor absténgase de proporcionar cualquier dato de identificación personal.
            </p>
        </div>
        <div v-if="curLang === 'pt'">
            <h2>Consultor de Imigração IA</h2>
            <p>
                Nosso consultor de imigração alimentado por IA possui um entendimento completo e abrangente das leis, regulamentos e políticas que governam a imigração, o status de refugiado, estudos no exterior e viagens transfronteiriças no Canadá, Austrália, Nova Zelândia e Estados Unidos. Ele vem acumulando extensos recursos de serviços relevantes, desde jurídicos até emissão de bilhetes. Ele estará aqui feliz em responder a quaisquer perguntas que você tenha a este respeito.
            </p>
            <p v-if="!toPost">
                Basta descrever detalhadamente sua situação específica/caso ao nosso consultor de imigração alimentado por IA, e ele ajudará você a tomar decisões informadas e desenvolver um plano preliminar para o seu ponto de partida. Para proteger sua privacidade e segurança de dados, por favor, abstenha-se de fornecer qualquer dado de identificação pessoal.
            </p>
        </div>
        <div v-if="curLang === 'vnm'">
            <h2>Tư vấn viên AI về nhập cư và du học</h2>
            <p>
                Tư vấn viên AI của chúng tôi có sự hiểu biết sâu rộng và toàn diện về luật nhập cư và tị nạn, luật quốc tịch, cũng như các quy định và chính sách liên quan ở Canada, Úc, New Zealand và Hoa Kỳ. Anh ấy cũng hiểu rõ về các nguồn tài nguyên dịch vụ liên quan. Tại đây, anh ấy sẽ tận tâm giải đáp mọi câu hỏi của bạn về nhập cư, du học, tình trạng tị nạn và du lịch quốc tế.
            </p>
            <p v-if="!toPost">
                Vui lòng mô tả cụ thể tình huống hoặc trường hợp của bạn, tư vấn viên AI của chúng tôi sẽ hướng dẫn bạn lập kế hoạch phù hợp ngay từ đầu và hỗ trợ bạn đưa ra quyết định có căn cứ. Để bảo vệ quyền riêng tư và an ninh dữ liệu của bạn, vui lòng tránh cung cấp bất kỳ thông tin nhận dạng cá nhân thực tế nào.
            </p>
        </div>
        <div v-if="curLang === 'far'" class="lang-far">
            <h2>مشاور هوش مصنوعی برای مهاجرت و تحصیل در خارج</h2>
            <p>
                مشاور هوش مصنوعی ما دارای دانش عمیق و جامع از قوانین مهاجرت و پناهندگی، قوانین شهروندی، و همچنین مقررات و سیاست‌های مربوطه در کانادا، استرالیا، نیوزیلند و ایالات متحده است. او همچنین از منابع خدمات مرتبط به خوبی آگاه است. در اینجا، او با دقت به تمامی سؤالات شما در مورد مهاجرت، تحصیل در خارج، وضعیت پناهندگی، و سفرهای بین‌المللی پاسخ خواهد داد.
            </p>
            <p v-if="!toPost">
                لطفاً شرایط یا مورد خود را به طور دقیق توضیح دهید، مشاور هوش مصنوعی ما شما را در برنامه‌ریزی اولیه هدایت می‌کند و به شما در اتخاذ تصمیمات مبتنی بر شواهد کمک می‌کند. برای محافظت از حریم خصوصی و امنیت داده‌های شما، لطفاً از ارائه هرگونه اطلاعات هویتی واقعی خودداری کنید.
            </p>
        </div>

        <iframe id="immig-frame" v-show="!toPost" :style="{ height: iframeHeight }" :src="initUrl"></iframe>

        <div id="immig-form" v-show="toPost">
            <div v-if="curLang === 'en'">
                <h3>Service Profile Submission Form</h3>
                <p>
                    Once you submit your company's basic profile data, our AI Immigration Consultant will extract information from your company website, making it inclusive for future retrieval and a potential source for AI-driven advice.
                </p>
            </div>
            <div v-if="curLang === 'fr'">
                <h3>Formulaire de Soumission de Profil de Service</h3>
                <p>
                    Une fois que vous aurez soumis les données de profil de base de votre entreprise, notre Consultant en Immigration IA extraira des informations depuis le site web de votre entreprise, les rendant disponibles pour des récupérations futures et une source potentielle pour des conseils pilotés par IA.
                </p>
            </div>
            <div v-if="curLang === 'cn'" class="lang-cn">
                <h3>服务商信息发布申请表</h3>
                <p>
                    在您提交了如下的公司基本信息后, 我们的人工智能移民顾问将访问您公司的网站并提取信息, 存档后作为将来的检索来源和推介参考。
                </p>
            </div>
            <div v-if="curLang === 'tcn'" class="lang-cn">
                <h3>服務商信息發布申請表</h3>
                <p>
                    在您提交了如下的公司基本信息後，我們的人工智慧移民顧問將訪問您公司的網站並提取信息，存檔後作為將來的檢索來源和推介參考。
                </p>
            </div>
            <div v-if="curLang === 'jp'">
                <h3>サービスプロバイダ情報公開申請フォーム</h3>
                <p>
                    以下のように貴社の基本情報を提出した後、当社のAI移民コンサルタントが貴社のウェブサイトにアクセスし情報を抽出し、将来の検索ソースおよび推薦参照としてアーカイブされます。
                </p>
            </div>
            <div v-if="curLang === 'kor'">
                <h3>서비스 제공자 정보 게시 신청서</h3>
                <p>
                    귀하가 아래와 같은 회사 기본 정보를 제출한 후, 우리의 인공지능 이민 컨설턴트는 귀사의 웹사이트를 방문하여 정보를 추출하고, 이를 미래의 검색 소스 및 추천 참조로서 보관합니다.
                </p>
            </div>
            <div v-if="curLang === 'vnm'">
                <h3>Biểu mẫu đăng ký thông tin nhà cung cấp dịch vụ</h3>
                <p>
                    Sau khi bạn đã gửi thông tin cơ bản về công ty dưới đây, cố vấn di trú AI của chúng tôi sẽ truy cập vào trang web của công ty bạn và trích xuất thông tin, sau đó lưu trữ để làm nguồn tra cứu và tham khảo đề xuất trong tương lai.
                </p>
            </div>
            <div v-if="curLang === 'es'">
                <h3>Formulario de Envío de Perfil de Servicio</h3>
                <p>
                    Una vez que envíes los datos básicos del perfil de tu empresa, nuestro Consultor de Inmigración IA extraerá información de la página web de tu empresa, haciéndola inclusiva para futuras recuperaciones y una fuente potencial para consejos impulsados por IA.
                </p>
            </div>
            <div v-if="curLang === 'pt'">
                <h3>Formulário de Submissão de Perfil de Serviço</h3>
                <p>
                    Uma vez que você submeta os dados básicos do perfil da sua empresa, nosso Consultor de Imigração AI extrairá informações do site da sua empresa, tornando-as acessíveis para futuras recuperações e uma fonte potencial para conselhos baseados em IA.
                </p>
            </div>
            <div v-if="curLang === 'far'" class="lang-far">
                <h3>فرم درخواست انتشار اطلاعات ارائه دهنده خدمات</h3>
                <p>
                    پس از ارائه اطلاعات پایه شرکت خود در زیر، مشاور مهاجرت AI ما به وب‌سایت شرکت شما دسترسی پیدا کرده و اطلاعات را استخراج می‌کند، سپس برای استفاده به عنوان منبع جستجو و مرجع توصیه در آینده آرشیو می‌شود.
                </p>
            </div>
            <form @submit.prevent="submitForm">
                <p>
                    <label>
                        <span v-if="!cnDisp">*Contact:</span>
                        <span v-if="cnDisp" class="lang-cn">*联系人:</span>
                    </label>
                    <input class="input-full" type="text" v-model="agent.name" placeholder="Full name like Mr. John Doe" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Phone:</span>
                        <span v-if="cnDisp" class="lang-cn">* 手机:</span>
                    </label>
                    <input class="input-full" type="tel" v-model="agent.phone" pattern="\+[0-9]{1,3}[-\s]?[(]?[0-9]{1,5}[)]?[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}" placeholder="Cell # like +1 (416) 123-4567" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Email:</span>
                        <span v-if="cnDisp" class="lang-cn">* 电邮:</span>
                    </label>
                    <input id="email-input" class="input-full" type="email" v-model="agent.email" placeholder="Business email" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Passcode:</span>
                        <span v-if="cnDisp" class="lang-cn">*验证码:</span>
                    </label>
                    <span class="input-full">
                        <input type="number" v-model="agent.ecode" placeholder="received from email" required />
                        <button type="button" v-if="!cnDisp" @click="requestPasscode">Request Passcode</button>
                        <button type="button" v-if="cnDisp" @click="requestPasscode">获取验证码</button>
                    </span>
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Website:</span>
                        <span v-if="cnDisp" class="lang-cn">* 网址:</span>
                    </label>
                    <input class="input-full" type="text" v-model="agent.website" placeholder="Company url, like abc.com/about" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">Caption:</span>
                        <span v-if="cnDisp" class="lang-cn">简注:</span>
                    </label>
                    <input class="input-full" type="text" v-model="agent.caption" placeholder="Keyword-based narration (Max 100 letters)" maxlength="100" />
                </p>
                <p v-if="submissionBusy" style="justify-content: center;">
                    <img src="@/assets/spinnerBlue.gif" alt="Wait..." style="width: 80px" />
                </p>
                <p v-if="submissionRtnMsg" style="margin-top: 32px;">
                    &emsp; <i class="fa fa-check" style="color: green; font-size: xx-large;"></i> 
                    &emsp; {{ timestamp }}
                    &emsp; <span style="text-decoration: underline;">{{ submissionRtnMsg }}</span>
                </p>
                <div class="btn-container">
                    <button type="submit">
                        <span v-if="!cnDisp">Submit</span>
                        <span v-if="cnDisp">提 交</span>
                    </button>
                </div>
            </form>
        </div>

        <div class="disclaimer" v-if="curLang === 'en' && !toPost">
            <p>
                Our AI immigration consultant is also accessible through <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad-travel-visa-refugee" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a>.
            </p>
            <p>
                For businesses serving new immigrants and students studying abroad in the immigration sector, please click <a href="#" @click.prevent="toggleForm">HERE</a> to list your services.
            </p>
            <h3>Disclaimer</h3>
            <p>
                This digital immigration consultant is powered by AI, specifically the GPT model, and has been trained on relevant legal texts, government program materials, and service market data. It provides immigration advice and solutions. However, as an AI but a human being, it is not eligible to obtain the licenses and certifications required for lawyers, registered consultants, or licensed advisors in the immigration field. Please exercise caution when considering the advice and solutions offered. AI Directly, the creator of this AI immigration consultant, assumes no responsibility for outcomes resulting from your reliance on its information output.
            </p>
        </div>
        <div class="disclaimer" v-if="curLang === 'fr' && !toPost">
            <p>
                Notre consultant en immigration AI est également accessible via <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad-travel-visa-refugee" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a>.
            </p>
            <p>
                Pour les entreprises servant les nouveaux immigrants et les étudiants étrangers dans le secteur de l'immigration, veuillez cliquer <a href="#" @click.prevent="toggleForm">ICI</a> pour lister vos services.
            </p>
            <h3>Avertissement</h3>
            <p>
                Ce consultant en immigration numérique est alimenté par l'IA, spécifiquement le modèle GPT, et a été formé sur des textes juridiques pertinents, des matériaux de programmes gouvernementaux et des données de marché des services. Il fournit des conseils et des solutions en matière d'immigration. Cependant, étant une IA et non un être humain, il n'est pas éligible pour obtenir les licences et certifications requises pour les avocats, les consultants enregistrés ou les conseillers agréés dans le domaine de l'immigration. Veuillez faire preuve de prudence lors de la prise en compte des conseils et des solutions proposés. AI Directly, le créateur de ce consultant en immigration IA, n'assume aucune responsabilité pour les résultats découlant de votre dépendance à ses informations.
            </p>
        </div>
        <div class="disclaimer lang-cn" v-if="curLang === 'cn' && !toPost">
            <p>
                我们的人工智能移民顾问还可以通过 <a href="https://chatgpt.com/g/g-5jA5DOoao-yi-min-liu-xue-nan-min-lu-xing-qian-zheng-zhong-guo-min-fa-dian-he-gong-si-fa-deng" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a> 访问。
            </p>
            <p>
                服务于移民留学人群的上下游公司请按 <a href="#" @click.prevent="toggleForm"><strong>这里</strong></a> 发布服务内容。
            </p>
            <h3>免责声明</h3>
            <p>
                这位数字移民顾问由人工智能 GPT 模型驱动, 精通诸多相关法律和政府移民计划，掌握相关服务市场方面的大量信息。它给您提供移民和留学方面的建议和方案。然而, 作为一个人工智能而非人类, 它无缘获得律师资格或注册移民顾问资格。所以请谨慎参考它提供的建议和方案。AI Directly, 这位人工智能移民顾问的制造者，不承担因您依赖其信息输出而产生的任何后果。
            </p>
        </div>
        <div class="disclaimer lang-cn" v-if="curLang === 'tcn' && !toPost">
            <p>
                我們的人工智慧移民顧問也可以透過 <a href="https://chatgpt.com/g/g-5jA5DOoao-yi-min-liu-xue-nan-min-lu-xing-qian-zheng-zhong-guo-min-fa-dian-he-gong-si-fa-deng" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a> 進行訪問。
            </p>
            <p>
                服務於移民與留學人群的相關企業，請按 <a href="#" @click.prevent="toggleForm"><strong>這裡</strong></a> 來發佈您的服務內容。
            </p>
            <h3>免責聲明</h3>
            <p>
                這位數位移民顧問由人工智慧GPT模型驅動，精通諸多相關法律及政府移民計劃，掌握相關服務市場方面的大量資訊。它為您提供移民和留學方面的建議和方案。然而，作為一個人工智慧而非人類，它無法獲得律師資格或註冊移民顧問資格。因此請謹慎參考它提供的建議和方案。AI Directly，這位人工智慧移民顧問的製造者，不承擔因您依賴其資訊輸出而產生的任何後果。
            </p>
        </div>
        <div class="disclaimer" v-if="curLang === 'jp' && !toPost">
            <p>
                私たちのAI移民コンサルタントは、<a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad-travel-visa-refugee" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a> でもご利用いただけます。
            </p>
            <p>
                移民や留学に関連する企業の方は、<a href="#" @click.prevent="toggleForm"><strong>こちら</strong></a> をクリックしてサービス内容を掲載してください。
            </p>
            <h3>免責事項</h3>
            <p>
                このデジタル移民コンサルタントは、人工知能GPTモデルによって駆動されており、関連する多くの法律と政府の移民プログラムを熟知しており、関連するサービス市場の広範な情報を掌握しています。これにより、移民や留学に関するアドバイスとソリューションを提供します。しかし、人工知能であるため、弁護士資格や登録移民コンサルタントの資格を取得することはできません。したがって、提供されるアドバイスやソリューションを慎重に検討してください。AI Directly、このAI移民コンサルタントの製作者は、その情報に依存した結果に対しては一切の責任を負いません。
            </p>
        </div>
        <div class="disclaimer" v-if="curLang === 'kor' && !toPost">
            <p>
                저희의 AI 이민 상담 서비스는 <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad-travel-visa-refugee" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a> 를 통해서도 이용하실 수 있습니다.
            </p>
            <p>
                이민 및 유학 관련 업계에 종사하는 회사는 <a href="#" @click.prevent="toggleForm"><strong>여기</strong></a> 를 클릭하여 서비스 내용을 게시해 주세요.
            </p>
            <h3>면책 조항</h3>
            <p>
                이 디지털 이민 컨설턴트는 인공지능 GPT 모델로 구동되며, 관련 법률과 정부의 이민 프로그램을 숙지하고 관련 서비스 시장에 대한 방대한 정보를 가지고 있습니다. 이를 통해 이민 및 유학에 관한 조언과 솔루션을 제공합니다. 그러나 인공지능으로서 변호사 자격이나 등록된 이민 컨설턴트 자격을 얻을 수는 없습니다. 따라서 제공되는 조언과 솔루션을 신중히 고려하시기 바랍니다. AI Directly, 이 AI 이민 컨설턴트의 제조사는 정보 의존 결과에 대해 어떠한 책임도 지지 않습니다.
            </p>
        </div>
        <div class="disclaimer" v-if="curLang === 'es' && !toPost">
            <p>
                Nuestro consultor de inmigración con IA también está disponible a través de <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad-travel-visa-refugee" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a>.
            </p>
            <p>
                Para empresas que atienden a nuevos inmigrantes y estudiantes que estudian en el extranjero en el sector de inmigración, por favor haga clic <a href="#" @click.prevent="toggleForm">AQUÍ</a> para listar sus servicios.
            </p>
            <h3>Descargo de responsabilidad</h3>
            <p>
                Este consultor de inmigración digital está impulsado por IA, específicamente por el modelo GPT, y ha sido entrenado en textos legales relevantes, materiales de programas gubernamentales y datos del mercado de servicios. Proporciona asesoramiento y soluciones sobre inmigración. Sin embargo, como una IA y no un ser humano, no es elegible para obtener las licencias y certificaciones requeridas para abogados, consultores registrados o asesores licenciados en el campo de la inmigración. Por favor, ejerza precaución al considerar los consejos y soluciones ofrecidos. AI Directly, el creador de este consultor de inmigración IA, no asume ninguna responsabilidad por los resultados derivados de su confianza en su información.
            </p>
        </div>
        <div class="disclaimer" v-if="curLang === 'pt' && !toPost">
            <p>
                Nosso consultor de imigração com IA também está acessível através do <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad-travel-visa-refugee" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a>.
            </p>
            <p>
                Para empresas que atendem novos imigrantes e estudantes que estudam no exterior no setor de imigração, por favor clique <a href="#" @click.prevent="toggleForm">AQUI</a> para listar seus serviços.
            </p>
            <h3>Aviso Legal</h3>
            <p>
                Este consultor de imigração digital é alimentado por IA, especificamente pelo modelo GPT, e foi treinado em textos legais relevantes, materiais de programas governamentais e dados do mercado de serviços. Ele oferece conselhos e soluções de imigração. No entanto, como uma IA e não um ser humano, ele não é elegível para obter as licenças e certificações necessárias para advogados, consultores registrados ou conselheiros licenciados na área de imigração. Por favor, tenha cautela ao considerar os conselhos e soluções oferecidos. AI Directly, a criadora deste consultor de imigração IA, não assume nenhuma responsabilidade pelos resultados decorrentes da sua dependência das informações fornecidas.
            </p>
        </div>
        <div class="disclaimer" v-if="curLang === 'vnm' && !toPost">
            <p>
                Tư vấn viên nhập cư AI của chúng tôi cũng có thể truy cập thông qua <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad-travel-visa-refugee" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a>.
            </p>
            <p>
                Các công ty liên quan đến dịch vụ nhập cư và du học, vui lòng nhấn <a href="#" @click.prevent="toggleForm"><strong>đây</strong></a> để đăng nội dung dịch vụ của bạn.
            </p>
            <h3>Điều khoản từ chối trách nhiệm</h3>
            <p>
                Cố vấn di trú số này được vận hành bởi AI mô hình GPT, thông thạo nhiều luật liên quan và các chương trình di trú của chính phủ, nắm giữ nhiều thông tin về thị trường dịch vụ liên quan. Nó cung cấp lời khuyên và giải pháp về di trú và du học. Tuy nhiên, vì là AI chứ không phải con người, nó không đủ điều kiện để có được giấy phép luật sư hoặc tư vấn di trú đăng ký. Do đó, xin hãy cẩn thận khi xem xét lời khuyên và giải pháp được cung cấp. AI Directly, nhà sản xuất cố vấn di trú AI này, không chịu trách nhiệm về kết quả do bạn dựa vào thông tin của nó.
            </p>
        </div>
        <div class="disclaimer lang-far" v-if="curLang === 'far' && !toPost">
            <p>
                مشاور هوش مصنوعی مهاجرتی ما همچنین از طریق <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad-travel-visa-refugee" target="_blank" rel="noopener noreferrer" @click.stop="">OpenAI ChatGPT</a> قابل دسترسی است.
            </p>
            <p>
                شرکت‌های مرتبط با خدمات مهاجرت و تحصیل در خارج، لطفاً بر روی <a href="#" @click.prevent="toggleForm"><strong>اینجا</strong></a> کلیک کنید تا محتوای خدمات خود را منتشر کنید.
            </p>
            <h3>سلب مسئولیت</h3>
            <p>
                این مشاور مهاجرت دیجیتال توسط مدل هوش مصنوعی GPT به کار گرفته شده است، در قوانین مربوطه و برنامه‌های دولتی مهاجرت تبحر دارد و اطلاعات گسترده‌ای در مورد بازار خدمات مرتبط دارد. این سیستم پیشنهادات و راه‌حل‌هایی در زمینه مهاجرت و تحصیل در خارج ارائه می‌دهد. با این حال، به عنوان یک هوش مصنوعی و نه انسان، واجد شرایط دریافت مجوزهای لازم برای وکلا یا مشاوران مهاجرت ثبت‌شده نیست. بنابراین لطفاً در نظر گرفتن پیشنهادات و راه‌حل‌های ارائه شده احتیاط کنید. AI Directly، سازنده این مشاور مهاجرت AI، هیچ مسئولیتی در قبال عواقب ناشی از تکیه بر اطلاعات آن ندارد.
            </p>
        </div>
    </section>
</template>

<script>
import { DateTime } from 'luxon';
import { ref, onMounted } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

export default {
    name: 'ImmigView',
    data() {
        return {
            agent: {
                name: '',
                phone: '',
                email: '',
                ecode: '',      // email passcode, potentially there could be a SMS passcode called tcode
                website: '',
                caption: ''
            },
            timestamp: '',
            submissionRtnMsg: '',
            submissionBusy: false
        }
    },
    setup() {
        const initUrl = `${process.env.VUE_APP_API_URL}/chat/html/aidi-func.html?com=ImmiConsult&p-url=${encodeURIComponent(process.env.VUE_APP_BASE_URL)}`;    // p-url is the parent url for the html in the iframe, this is for security control that prevents talk to unknow source called the html

        // initialize the display language and whether the page be on post
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn, tcn, or jp, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const langParam = queryParams.get('lang');
        const curLang = ref((langParam) ? langParam : 'en');
        const cnDisp = ref(['cn', 'tcn', 'jp'].includes(curLang.value));
        const toPost = ref(queryParams.get('page') === 'post');

        /* 2024-8-30 shall be deprecated since v-show is used
        if (toPost.value) {
            setTimeout(() => {
                document.getElementById('immig-frame').style.display = 'none';      // shall not use v-if to toggle, otherwise the dialogue in the <iframe> will be lost on each toggle
            }, 200);
        }*/

        /* let the JS in the <iframe> post a message that contains the document height, then change the height of the <iframe> here accordingly */
        /* this is mixed with 'about-us' toggle */
        const iframeHeight = ref((window.innerWidth < 1024) ? '230px' : '180px');   // Initialize with a default or initial height
        const handleIframeMessage = (event) => {
            if (event.origin === process.env.VUE_APP_API_URL && typeof event.data.frame_height === "number") {
                iframeHeight.value = `${event.data.frame_height + 10}px`;   // Update the height reactively
                setTimeout(() => {      // wait until the <iframe> is updated by ref, which is iframeHeight in this case
                    document.getElementById("immig-frame").scrollIntoView({ behavior: 'smooth', block: 'end' });
                }, 60);
            }
        }

        onMounted(() => {
            document.getElementById('gpt-icon').style.display = 'none';
            document.getElementById('gpt-box').style.display = 'none';
            document.getElementById('about-us').style.display = 'none';
            window.addEventListener('message', handleIframeMessage);   // Set up the event listener for receiving messages from <iframe>
        });

        onBeforeRouteLeave((to, from, next) => {
            document.getElementById('gpt-icon').style.display = 'block';
            document.getElementById('about-us').style.display = 'block';
            window.removeEventListener('message', handleIframeMessage);
            next();
        });

        return { initUrl, curLang, cnDisp, toPost, iframeHeight };
    },
    
    methods: {
        toggleForm() {
            this.toPost = !this.toPost;
            this.submissionRtnMsg = '';
            //this.submissionBusy = false;

            //this is replaced by v-show --> const immigFrame = document.getElementById('immig-frame');
            if (this.toPost) {
                //immigFrame.style.display = 'none';      // can't use v-if to toggle, otherwise the dialogue in the <iframe> will be lost on each toggle
                setTimeout(() => {
                    document.getElementById('immig-form').scrollIntoView({ behavior: 'smooth', block: 'end' })
                }, 60);
            } /*else {
                immigFrame.style.display = 'block';
            }*/
        },

        requestPasscode() {
            // disable the Email input first
            document.getElementById('email-input').disabled = true;

            //
            fetch(`${process.env.VUE_APP_API_URL}/chat/passcode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: 'ImmiConsult', tag: this.agent.email })
            })
            .then(response => {
                if (response.ok) {
                    alert(`A passcode has been sent to your email box.\n\nIt expires in ${process.env.VUE_APP_PASSCODE_LIFECYCLE} minutes.`);
                } else {  
                    alert(`Failed to send a passcode. Please try it again later.`);
                }
            })
            .catch(error => {
                alert(`Network error: ${error}`);
            });
        },

        submitForm() {
            if (this.submissionBusy) {    // because the server response takes time, multi submissions during this period shalll be prevented
                return;
            } else {
                this.submissionBusy = true;
            }

            try {
                let { website } = this.agent;
                if (!website.startsWith('https://') && !website.startsWith('http://')) {
                    website = 'https://' + website; 
                }
                new URL(website);     // validate the url input for the Website, otherwise an error may occur

                fetch(`${process.env.VUE_APP_API_URL}/chat/immig`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ coid: 'ImmiConsult', ...this.agent })
                })
                .then(response => {
                    if (response.ok) {
                        this.timestamp = `${DateTime.now().setZone("America/Toronto").toFormat('yyyy-MM-dd, HH:mm:ss')} EDT`;
                        this.submissionRtnMsg = `Your submission is successfully accepted and will be processed shortly.`;
                        this.agent = { name: '', phone: '', email: '', ecode: '', website: '', caption: '' };     // reset the <form>

                        setTimeout(() => {
                            document.getElementById('immig-form').scrollIntoView({ behavior: 'smooth', block: 'end' })
                        }, 50);
                    } else {              // This tends to handle HTTP status codes that are in 400 and 500 families, but may also include that in the uncommon 100 and 300 families
                        const msg = (response.status < 500) 
                            ? `Failed to submit. Please correct your data or maybe shorten it and try again.` 
                            : `Submission failed due to a server error. Please inform info@ai-directly.com or try it later.`;

                        alert(`${response.status} - ${response.statusText}\n\n${msg}\n\n${ JSON.stringify(response.json()) }`);
                    }
                })
                .catch(error => {       // catches the error from 'fetch'
                    alert(`Submission failed due to network errors. Please try again later.\n\n${error.message}`);
                });

            } catch (error) {       // catches the error on 'new URL()'
                alert("Invalid URL for the Website input.");
            }

            this.submissionBusy = false;
        }
    }
}
</script>

<style scoped>
/****************** */
/* Language control */
/****************** */
.lang-cn,
.lang-cn p,
.lang-cn h2,
.lang-cn h3 {
    letter-spacing: 0.06em;
}

.lang-far p,
.lang-far h2,
.lang-far h3 {
    text-align: right;
}

.lang-far h3,
.lang-far p {
    font-size: larger;
}


/* other */
#op-wrap {
    display: flex;
    justify-content: flex-end;
    margin: 12px 6% 0 0;
}

#op-wrap select,
#op-wrap button {
    width: 100px;
    height: 32px;
    margin: auto 8px;
    padding: 4px;
    background-color: #fbf0e6;
    border: 1px dashed lightgray;
    border-radius: 6px;
    box-shadow: none;
    color: #004b7a;
    font-family: Verdana, sans-serif;
    cursor: pointer;
}

#op-wrap select:focus,
#op-wrap button:focus {
    outline: none;
    border-color: #004b7ae0;
}

@media screen and (max-width: 1023px) {
    #op-wrap {
        margin-right: 2%;
    }

    #op-wrap select,
    #op-wrap button {
        width: 84px;
        height: 30px;
        margin-right: 2px;
        border-radius: 5px;
        font-size: 12px;
    }
}

h2 {
    margin-top: 16px;
}

iframe {
    margin: 0;
    padding: 0;
    width: 100%;
    border: 0;
    overflow: scroll;    /* Always show scrollbars */
}


/********* */
/* Form UI */
/********* */
#immig-form {
    margin-top: 32px;
    padding-bottom: 32px;
}

form {
    max-width: 1024px;
    padding: 16px 0;
}

form p {
    display: flex;    /* Enables flexbox */
    align-items: center;    /* Centers items vertically */
    width: 100%;
}

form label {
    flex: 20%;    /* Does not grow, does not shrink, takes up 20% of the space */
    padding-right: 16px;
    text-align: right;
}

form input {
    padding: 15px 12px 13px 12px;
    border: 1px solid lightgray;
    border-radius: 3px;
    font-size: inherit;
}

form input:focus {
    outline: none;
    border-color: #004b7ae0;
}

.input-full {
    flex: 80%;    /* Takes the remaining space */
}

span.input-full {
    flex: 83%;
    display: inline-flex;
}

.input-full input {
    flex: 60%;    /* Takes the remaining space */
    margin: 0 12px 0 0;
}

.input-full button {
    flex: 40%;
    background-color: #fbf0e6;
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.1);
    transition: all 0.2s ease;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
}

.input-full button:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}


.input-full button:active {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    transform: translateY(2px);
    background-color: #f0e0d0; /* Slightly darker shade when pressed */
}

.btn-container {
    display: flex;
    justify-content: center;
}

.btn-container button {
    width: 38%;
    margin-top: 40px;
    padding: 13px 10px 11px 10px;
    border-color: gray;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.1);
    transition: all 0.3s ease;
    background: #004b7ae0;
    color: white;
    cursor: pointer;
    font-size: inherit;
    place-items: center;
}

.btn-container button:hover {
    border-color: lightgray;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    background: linear-gradient(135deg, #004b7ad0, #004b7af0);
}

.btn-container button:disabled {
    cursor: not-allowed;     /* Cursor style to indicate it's not clickable */
}

@media screen and (max-width: 1023px) {
    form label {
        flex: 24%;
        padding-right: 6px;
    }

    form input {
        padding: 10px;
    }

    .input-full {
        flex: 76%;
    }

    span.input-full {
        flex: 82%;
    }

    .input-full input {
        flex: 42%;
        width: 40px;       /* to counter offset VUE3 default */
        min-width: 40px;    /* to counter offset VUE3 default */
        margin-right: 8px;
    }

    .input-full button {
        flex: 58%;
        font-family: Verdana, sans-serif;
        font-size: smaller;
    }

    .btn-container button {
        width: 68%;
        margin-top: 26px;
        padding: 10px;
    }
}

/* below is the setting to take off the incremental/incremental spin from Number Input
/* For WebKit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>